
(function() {
    'use strict';

    $(document).ready( function() {

        const $searchInput = $('#search-input');
        const $searchForm  = $searchInput.closest('form');
        const $searchContainer = $searchInput.closest('.form-wrapper');
        const $searchLoader = $('.search-loader');
        const $searchNoPosts = $('.search-no-posts');
        const $searchError = $('.search-error');
        const $postsWrapper = $('#search-posts-wrapper');

        /** Search news on enter click **/
        $searchInput.on('keyup keypress', function (e) {
            let keyCode = e.keyCode || e.which;
            if ( keyCode === 13 ) {
                e.preventDefault();
                getPostsByPhrase();
            }
        });


        /**
         * @function Disable fields on AJAX load
         */
        function disableFields() {
            $('[type=text], [type=hidden], [type=button], [type=submit]').attr('disabled', true);
        }

        /**
         * @function Enable fields after AJAX load
         */
        function enableFields() {
            $('[type=text], [type=hidden], [type=button], [type=submit]').attr('disabled', false);
        }

        function getPostsByPhrase() {
            let phrase = $searchInput.val();
            let startTime = new Date().getTime();

            $.ajax({
                url: "/wp-admin/admin-ajax.php",
                method: "get",
                timeout: 10000,
                data: {
                    action: 'search_posts_by_phrase',
                    phrase: phrase,
                },
                cache: false,
                success: function (data) {
                    let successLoadingTime = new Date().getTime();
                    let timeout = parseInt(successLoadingTime - startTime);

                    if ( data.length === 0 ) {
                        $searchLoader.fadeOut();
                        setTimeout(function () {
                            $searchForm.fadeIn();
                            $searchNoPosts.fadeIn();
                        }, 300);
                    } else {
                        if ( timeout < 2000 ) {
                            setTimeout(function () {
                                $searchLoader.fadeOut();
                                $searchForm.fadeIn();
                                $postsWrapper.html(data).fadeIn();
                            }, timeout);
                        } else {
                            $searchLoader.fadeOut();
                            $searchForm.fadeIn();
                            $postsWrapper.html(data).fadeIn();
                        }
                    }
                },
                error: function (error) {
                    $searchLoader.fadeOut();
                    console.error(error);
                    setTimeout(function () {
                        $searchForm.fadeIn();
                        $searchError.fadeIn();
                    }, 300);
                },
                beforeSend: function () {
                    disableFields();
                    $searchError.fadeOut();
                    $searchNoPosts.fadeOut();
                    $postsWrapper.fadeOut();
                    $searchForm.fadeOut();
                    setTimeout(function () {
                        $searchLoader.css({display: 'flex'}).fadeIn();
                    }, 500);
                },
                complete: function () {
                    enableFields();
                },
            });
        }

    });

}(jQuery));