(function () {
    'use strict';
    $(document).ready(function () {

        let isMenuOpen = false;
        let isSearchOpen = false;
        const $logo = $(".report-logo");
        const $name = $(".report-name");
        const $toggleSearch = $('.toggle-search');

        //Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        if ($(window).width() > 767) {

            $logo.on("mouseover", function () {
                if ($name.hasClass("report-name-subpage")) {
                    $name.css("display", "flex");
                }
            });

            $logo.on("mouseleave", function () {
                if ($name.hasClass("report-name-subpage")) {
                    $name.css("display", "none");
                }
            });
        }

        // Update animations height
        updateAnimationsHeight();
        $(window).on('resize', () => {
            updateAnimationsHeight();
            handleMenuOnResize();
        })

        function updateAnimationsHeight() {
            $('.animation-origin[data-aspect-ratio]').each(function () {
                if ($(this).parent().hasClass('image__static-gif')) {
                    const width = $(this).innerWidth();
                    const aspectRatio = parseFloat($(this).data('aspect-ratio'));

                    $(this).parent().css('padding-top', width * aspectRatio);
                }
            });
        }

        function handleMenuOnResize() {
            if (isMenuOpen)
                hideSplash('menu');
            if (isSearchOpen)
                hideSplash('search');
        }

        // Fixed hamburger on scroll
        let lastScrollTop = 0;
        const $headerRight = $('.header-right');
        const $header = $('header');

        if (window.innerWidth < 1200) {
            window.isHamburgerHidable = true;
        } else {
            window.isHamburgerHidable = !$('.homepage').length;
        }

        $(window).scroll(function () {
            // let st = window.pageYOffset || document.documentElement.scrollTop;
            let st = $(window).scrollTop();

            if (!isMenuOpen && !isSearchOpen) {
                if (st > $header.innerHeight()) {
                    if (!$header.hasClass('scrolled'))
                        $header.addClass('scrolled');
                } else {
                    if ($header.hasClass('scrolled'))
                        $header.removeClass('scrolled');
                }

                if (st > lastScrollTop) {
                    // downscroll code
                    if (!$header.hasClass('offset')) $header.addClass('offset');
                } else if (st < lastScrollTop) {
                    // upscroll code
                    if ($header.hasClass('offset')) $header.removeClass('offset');
                }

                lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
            }

        })


        //Menu mobile toggle
        $(".menu-toggle").click(function () {
            const tl = gsap.timeline();

            if (isSearchOpen) {
                hideSplash('search', tl);
            } else {
                if (isMenuOpen) {
                    hideSplash('menu', tl);
                } else {
                    showSplash('menu', tl);
                }
            }

        });

        //Search trigger
        $toggleSearch.click(function () {
            const tl = gsap.timeline();

            if (isMenuOpen) {
                hideSplash('menu', tl);
            }

            if (isSearchOpen) {
                hideSplash('search', tl);
            } else {
                showSplash('search', tl);
            }

        });

        const GSAP_TIMING = 0.3;

        function hideSplash(overlay, _tl = null) {
            const tl = _tl ? _tl : gsap.timeline();
            tl.to('.header-container', { duration: GSAP_TIMING, autoAlpha: 0 })
            tl.set(`.${overlay}-overlay`, { pointerEvents: 'none' })
            tl.to(`.${overlay}-overlay .content-wrapper`, { duration: GSAP_TIMING, autoAlpha: 0 }, `-=${GSAP_TIMING}`)
            tl.to(`.${overlay}-overlay .bg`, { duration: GSAP_TIMING, scale: 0 })
            tl.to('.header-container', {
                duration: GSAP_TIMING,
                position: 'relative',
                autoAlpha: 1
            }, `-=${GSAP_TIMING}`)
            tl.to('.report-name', { duration: GSAP_TIMING, autoAlpha: 1 })
            tl.set('.header-container', { clearProps: 'all' })
            tl.set('.report-name', { clearProps: 'all' })
            tl.set('.header', { clearProps: 'all' })

            $headerRight.find('.menu-toggle').removeClass('open');

            let st = $(window).scrollTop();
            if (st > $header.innerHeight()) {
                $header.addClass('offset');
            }


            if (overlay === 'search') {
                $toggleSearch.removeClass('closed');
                isSearchOpen = false;
            }
            if (overlay === 'menu')
                isMenuOpen = false;

            return tl;
        }

        function showSplash(overlay, _tl = null) {
            const tl = _tl ? _tl : gsap.timeline();
            tl.to('.header-container', { duration: GSAP_TIMING, autoAlpha: 0 })
            tl.to('.report-name', { duration: GSAP_TIMING, autoAlpha: 0 })
            tl.to(`.${overlay}-overlay .bg`, { duration: GSAP_TIMING, scale: 1.5 }, `-=${GSAP_TIMING}`)
            tl.to(`.${overlay}-overlay .content-wrapper`, { duration: GSAP_TIMING, autoAlpha: 1, })
            tl.set('.header', { minHeight: $('.header-container').innerHeight() })
            tl.to('.header-container', {
                duration: GSAP_TIMING,
                position: 'fixed',
                width: '100%',
                autoAlpha: 1
            }, `-=${GSAP_TIMING}`)
            tl.set(`.${overlay}-overlay`, { pointerEvents: 'initial' })
            $headerRight.find('.menu-toggle').addClass('open');

            $header.removeClass('offset');
            $header.removeClass('scrolled');

            if (overlay === 'search') {
                $toggleSearch.addClass('closed');
                isSearchOpen = true;
            }
            if (overlay === 'menu')
                isMenuOpen = true;

            return tl;
        }

        // focus input in search
        $('.overlay.search-overlay').on('click', function () {
            $(this).find('#search-input').focus()
        });

        $("#newsletter_form").submit(function(e) {

            e.preventDefault(); // avoid to execute the actual submit of the form.

            var email = $('#newsletter_input').val();

            $("#newsletter_form .error").removeClass('error');
            var valid = true;
            if(!email){
                valid = false;
                $('#newsletter_input').addClass('error');
            }
            if(!$('#rodo_checkbox').is(':checked')){
                valid = false;
                $('#rodo_checkbox').parent().addClass('error');
            }

            if(!valid) return;

            $("#newsletter_form button").attr('disabled', 'true');

            $.ajax({
                url: "/wp-admin/admin-ajax.php",
                method: "GET",
                data: {
                    action: 'signup_newsletter',
                    email: email,
                },
                success: function(data)
                {
                    $("#newsletter_form .success").show();
                }
            });


        });
    });
}(jQuery));
