(function () {
    'use strict';
    $(document).ready(function () {

        // accordion scroll animation
        $('.subpage .accordion.scroll-animation .collapse, .subpage .accordion .collapse').on('shown.bs.collapse', function (e) {
            var positionTop = $(this).parent().offset().top; // - $('header').height();
            $('html, body').animate({
                scrollTop: positionTop
            }, 500);
            $(window).resize();
        });

        // responsive image enlarging
        $('.popup-trigger').click(function (e) {
            $(this).parent().find('.popup-image-container').fadeIn();
            e.preventDefault();
            return false;
        });

        $('.popup-image-container').click(function () {
            $(this).fadeOut();
        });

        const $timelineSwiper = $('.timeline-swiper');


        $timelineSwiper.each(function (index) {
            const $this = $(this);
            const $nav = $(this).find('.swiper-navigation');

            const updateNavTop = () => {
                const firstSlideImageHeight = $this.find('.swiper-slide .image-wrapper').first().innerHeight();
                $nav.css('top', firstSlideImageHeight / 2);
            };

            $(window).on('resize', updateNavTop);

            new Swiper($this[0], {
                spaceBetween: 90,
                slidesPerView: 4,
                centeredSlides: false,
                breakpoints: {
                    1366: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    767: {
                        slidesPerView: 2,
                    },
                    575: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        centeredSlides: true
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    init: () => {
                        updateNavTop();
                        $this.imagesLoaded(function () {
                            updateNavTop();
                        });
                    }
                }
            });
        });

        // Accordion collapse
        $('.accordion .collapse').on('click', function () {
            if ($(this).hasClass('show'))
                $(this).collapse('hide')

        });


        // Custom number of slides per view
        const $relationsSwiper = $('.relations-swiper');

        const subpageBreakpoints = {
            575: {slidesPerView: 1.15},
            767: {slidesPerView: 1.5},
            991: {slidesPerView: 1.75},
            1024: {slidesPerView: 2.25},
            1366: {slidesPerView: 3},
        };

        const homepageBreakpoints = {
            575: {slidesPerView: 1.15},
            767: {slidesPerView: 1.5},
            991: {slidesPerView: 1.75},
            1199: {slidesPerView: 1.75},
            1366: {slidesPerView: 2.1},
        }

        $relationsSwiper.each(function () {
            new Swiper($(this)[0], {
                spaceBetween: 22,
                slidesPerView: 'auto',
                breakpoints: $('.homepage').length ? homepageBreakpoints : subpageBreakpoints,
            });
        });

        const $imagesSwiper = $('.images-swiper');
        $imagesSwiper.each(function (index) {
            new Swiper($(this)[0], {
                slidesPerView: 1,
                spaceBetween: 0,
                autoHeight: true,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                on: {
                    transitionStart: function () {
                        $(".image-desc").hide();
                    },
                    transitionEnd: function () {
                        $(".image-desc").show();
                    }
                }

                // lazy: {
                //     loadPrevNext: true
                // },

            })
        });


        if ($('.contact').length) {
            $('.bio-toggle').on('click', function () {
                $(this).find('span').toggleClass('d-none');
                $(this)
                    .parents('.employee-single')
                    .find('.bio').slideToggle();
            })
        }


        if (window.animationsArray) {
            for (var i = 0; i < window.animationsArray.length; i++) {
                animationsArray[i]();
            }
        }

        $('.photo-grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            gutter: '.gutter-sizer',
            percentPosition: true
        });

        $(".search-container .tags .tags-title").on("click", function () {
            $(this).siblings("ul").slideToggle();
        })

        $(".grid-item>img").on('click', function () {
            $('.popup-backdrop').addClass('show');
            $(this).siblings('.popup').addClass('show');
            $('body').addClass('no-scroll');
        })

        $(".popup .close").on('click', function () {
            $('.popup-backdrop').removeClass('show');
            $(this).parents('.popup').removeClass('show');
            $('body').removeClass('no-scroll');
        })

        $(".popup .next").on('click', function () {
            $(this).parents('.popup').removeClass('show');
            const nextItem = $(this).parents('.grid-item').next(".grid-item").find('.popup');
            if (nextItem.length) {
                nextItem.addClass('show');
            } else {
                $('.popup-backdrop').removeClass('show');
                $('body').removeClass('no-scroll');
            }
        })

        $(".popup .prev").on('click', function () {
            $(this).parents('.popup').removeClass('show');
            const prevItem = $(this).parents('.grid-item').prev(".grid-item").find('.popup');
            if (prevItem.length) {
                prevItem.addClass('show');
            } else {
                $('.popup-backdrop').removeClass('show');
                $('body').removeClass('no-scroll');
            }
        })

    });
}(jQuery));
