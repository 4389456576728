(function () {
    'use strict';

    $(document).ready(function () {

        if ($('.archive').length) {

            var pageNews = 1; // Current posts page
            var pppNews = 5; // Posts per page

            const $relationsWrapper = $('#relations-wrapper');
            const $noPosts = $('.no-posts');
            const $error = $('.error');
            const $loader = $('.loader');

            // Fire AJAX on IE on window load
            if (window.document.documentMode) {
                setTimeout(function () {
                    getNews();
                }, 500);
            }

            $(window).on('load', function () {
                getNews();
            });

            const $searchDropdownBtn = $('.search-dropdown');
            const $archiveDropdownLinks = $('#year-search li a, #month-search li a');
            const $baseInput = $('#base');
            const $phraseInput = $('#phrase');
            const $searchBtn = $('#search');
            const $yearSearch = $('#year-search');
            const $monthSearch = $('#month-search');
            const $cleanSearch = $('#clean-search');

            // Slide toggle dropdown search
            $searchDropdownBtn.on('click', function () {
                let targetParam = $(this).data('target');
                let $targetDropdown = $(`#${targetParam}`);
                $targetDropdown.slideToggle();
                $(this).find('.icon-container').toggleClass('active');
                // $('html, body').animate({scrollTop: $targetDropdown.offset().top}, 600);
            });

            // Get the values from dropdowns on click
            $archiveDropdownLinks.on('click', function (e) {
                e.preventDefault();

                let value = $(this).data('value');
                let $ul = $($(this).closest('ul')[0]);
                let $btn = $(this).closest('.form-group').find('.search-dropdown');

                $ul.attr('data-value-selected', value);

                if ($yearSearch.attr('data-value-selected')) {
                    showMonthSearchGroup();
                } else {
                    hideMonthSearchGroup();
                }

                showCleanSearchButton();
                $ul.slideUp(500);
                $btn.find('.icon-container').removeClass('active');
                $btn.find('.selected').html(` / ${$(this).html()}`);
                pageNews = 0;
                getNews();
            });

            // Get posts on search click
            $searchBtn.on('click', function (e) {
                e.preventDefault();
                showCleanSearchButton();
                pageNews = 0;
                getNews();
            });

            // Get posts on enter click
            $phraseInput.on('keyup', function (e) {
                if (e.keyCode === 13) {
                    showCleanSearchButton();
                    pageNews = 0;
                    getNews();
                }
            });

            // Get posts from chosen page on click
            $(document).on('click', '.pagination .page-numbers:not(.dots, .current, .next, .prev)', function (e) {
                e.preventDefault();
                pageNews = parseInt($(this).text());
                getNews();
            });

            // Get posts from next page on arrow next click
            $(document).on('click', '.pagination .next.page-numbers', function (e) {
                e.preventDefault();
                pageNews = parseInt($(this).siblings('.current').text()) + 1;
                getNews();
            });

            // Get posts from previous page on arrow prev click
            $(document).on('click', '.pagination .prev.page-numbers', function (e) {
                e.preventDefault();
                pageNews = parseInt($(this).siblings('.current').text()) - 1;
                getNews();
            });

            // Clean filters on 'clean-search' button click
            $cleanSearch.on('click', function (e) {
                e.preventDefault();
                cleanInputFields();
                $monthSearch.slideUp(300);
                hideMonthSearchGroup();
                $yearSearch.slideUp(300);

                pageNews = 0;
                getNews();
                hideCleanSearchButton();
            });

            /**
             * @function Show month search container
             */
            function showMonthSearchGroup() {
                $('.month-search-group').slideDown(300);
            }

            /**
             * @function Hide month search container
             */
            function hideMonthSearchGroup() {
                $('.month-search-group').slideUp(300);
            }

            /**
             * @function Show month search container
             */
            function showCleanSearchButton() {
                if ($phraseInput.val() ||
                    $yearSearch.attr('data-value-selected') ||
                    $monthSearch.attr('data-value-selected'))
                    $('.clean-search-group').slideDown(300);
            }

            /**
             * @function Hide month search container
             */
            function hideCleanSearchButton() {
                $('.clean-search-group').slideUp(300);
            }

            /**
             * @function Clean selected values from labels
             */
            function cleanSelectedValuesFromLabels() {
                $('.search-dropdown').each(function () {
                    $(this).find('.selected').html('');
                });
            }

            /**
             * @function Clean all input fields
             **/
            function cleanInputFields() {
                $phraseInput.val('');
                $yearSearch.attr('data-value-selected', '');
                $monthSearch.attr('data-value-selected', '');

                let currentYear = (new Date).getFullYear();

                cleanSelectedValuesFromLabels();
            }

            /**
             * @function Disable fields on AJAX load
             */
            function disableFields() {
                $('[type=text], [type=hidden], [type=button], [type=submit]').attr('disabled', true);
            }

            /**
             * @function Enable fields after AJAX load
             */
            function enableFields() {
                $('[type=text], [type=hidden], [type=button], [type=submit]').attr('disabled', false);
            }

            /**
             * @function Scroll to first post
             */
            function scrollToFirstPost() {
                if ($('.subpage.relations').length && location.hash === '#archive') {
                    const $relationsWrapper = $('.relations-wrapper');
                    $('html, body').animate({scrollTop: $relationsWrapper.offset().top + 200}, 600);
                }
            }

            /**
             * @function Load news from server
             */
            function getNews() {
                let base = $baseInput.val();
                let phrase = $phraseInput.val();
                let year = $yearSearch.attr('data-value-selected');
                let month = $monthSearch.attr('data-value-selected');
                let startTime = new Date().getTime();

                $.ajax({
                    url: "/wp-admin/admin-ajax.php",
                    method: "post",
                    timeout: 10000,
                    data: {
                        action: 'get_news',
                        base: base,
                        phrase: phrase,
                        year: year,
                        month: month,
                        page: pageNews,
                        ppp: pppNews,
                    },
                    cache: false,
                    success: function (data) {
                        let successLoadingTime = new Date().getTime();
                        let timeout = parseInt(successLoadingTime - startTime);

                        if (data.length === 0) {
                            $loader.fadeOut();
                            setTimeout(function () {
                                $noPosts.fadeIn();
                            }, 300);
                        } else {
                            if (timeout < 2000) {
                                setTimeout(function () {
                                    $loader.fadeOut();
                                    $relationsWrapper.html(data).fadeIn();
                                }, timeout);
                            } else {
                                $loader.fadeOut();
                                $relationsWrapper.html(data).fadeIn();
                            }
                        }

                        scrollToFirstPost();
                    },
                    error: function (error) {
                        $loader.fadeOut();
                        console.error(error);
                        setTimeout(function () {
                            $error.fadeIn();
                        }, 300);
                        scrollToFirstPost();
                    },
                    beforeSend: function () {
                        disableFields();
                        $error.fadeOut();
                        $noPosts.fadeOut();
                        $relationsWrapper.fadeOut();
                        setTimeout(function () {
                            $loader.fadeIn();
                        }, 300);
                    },
                    complete: function () {
                        enableFields();
                    },
                });
            }
        }
    });
}(jQuery));
