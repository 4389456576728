(function () {
    'use strict';
    let hash = window.location.hash;
    if (hash === '#newsletter')
        window.location.hash = "";

    $(window).load(function () {
        if (hash === '#newsletter' && $(hash).length) {
            $("html, body").animate({
                scrollTop: $(hash).offset().top
            }, 1000);
        }
    })


    $(document).ready(function () {
        if ($('.homepage').length) {

            let st = $(window).scrollTop();
            ScrollTrigger.matchMedia({
                "(min-width: 1200px)": function () {
                    ScrollTrigger.create({
                        trigger: ".events-wrapper",
                        pin: "#pin-news",
                        start: "top " + $('.events-wrapper').offset().top + "px",
                        end: "bottom " + $('#pin-news').innerHeight() + "px",
                        // markers: {startColor: "green", endColor: "red", fontSize: "12px"},
                        onUpdate: () => {
                            // console.log("self", self)
                            st = $(window).scrollTop();
                            // window.isHamburgerHidable = st >= self.end;
                        }
                    });
                }
            });

            // if (window.innerWidth < 1200) {
            //     window.isHamburgerHidable = true;
            // }

        }
    });
}(jQuery));
